import AIPluginPlayContainer from "../containers/AIPluginPlayContainer";

const AIPluginPlayPage = () => {
  return (
    <>
      <AIPluginPlayContainer />
    </>
  );
};

export default AIPluginPlayPage;
