import {
  HomeDemo2IconsH1,
  HomeDemo2IconsH11,
  HomeDemo2IconsH2,
  HomeDemo2IconsH22,
  HomeDemo2IconsH3,
  HomeDemo2IconsH33,
  HomeDemo2Feature1,
  HomeDemo2Feature2,
  HomeDemo2Feature3,
  HomeDemo2Feature4,
  HomeDemo2Feature5,
  HomeDemo2FeaturesS1,
  HomeDemo2FeaturesS2,
  HomeDemo2FeaturesS3,
  HomeDemo2FeaturesS4,
  HomeDemo2FeaturesF1,
  HomeDemo2FeaturesF2,
  HomeDemo2FeaturesF3,
  HomeDemo2FeaturesF4,
  HomeDemo2FeaturesF5,
  HomeDemo2FeaturesF6,
  HomeDemo2BlogImg1,
  HomeDemo2BlogImg2,
  HomeDemo2BlogImg3,
} from "../../../utils/allImgs";

export const HowItWorksInfo = [
  {
    img1: HomeDemo2IconsH1,
    img2: HomeDemo2IconsH11,
    steps: 1,
    title: "Precision",
    text: "Harnessing vast datasets to make highly accurate predictions.",
  },
  {
    img1: HomeDemo2IconsH2,
    img2: HomeDemo2IconsH22,
    steps: 2,
    title: "Adaptability",
    text: "Continuously learning from new data to refine and improve predictions over time.",
  },
  {
    img1: HomeDemo2IconsH3,
    img2: HomeDemo2IconsH33,
    steps: 3,
    title: "Insight",
    text: "Delving deep into data patterns to uncover hidden insights and trends.",
  },
];

export const ServicesinfoUp = [
  {
    img: HomeDemo2Feature1,
    title: "Foundation & Vision",
    text: "Establish core infrastructure and initiate key collaborations.",
  },
  {
    img: HomeDemo2Feature2,
    title: "Launchpad & Onboarding",
    text: "Roll out Launchpad, develop project tools, and host events.",
  },
  {
    img: HomeDemo2Feature3,
    title: " Social Mining ",
    text: "Launch platform, reward user engagement, and host community events.",
  },
  {
    img: HomeDemo2Feature4,
    title: " ISO & Engagement",
    text: "Introduce ISO platform, develop review system, and organize events.",
  },
  {
    img: HomeDemo2Feature5,
    title: " Trading Ecosystem ",
    text: "Introduce AI-driven trading tools and conduct training sessions",
  },
];
export const ServicesinfoDown = [
  {
    img: HomeDemo2Feature1,
    title: " Security & Trust",
    text: "Implement protection mechanisms, publish reports, and host security events.",
  },
  {
    img: HomeDemo2Feature2,
    title: "AI & Data Integration",
    text: "Develop AI algorithms, integrate insights, and host AI events.",
  },
  {
    img: HomeDemo2Feature3,
    title: " Team & Skill Enhancement",
    text: "Recruit experts, train the team, and launch interaction platform.",
  },
  {
    img: HomeDemo2Feature4,
    title: "AI Plugin Play",
    text: "Launch platform, create AI plugin library, and conduct workshops.",
  },
  {
    img: HomeDemo2Feature5,
    title: "Integration & Outreach",
    text: " Ensure seamless platform integration, explore global expansion, and set future vision.",
  },
];

export const SmartContractinfoRight = [
  {
    img: HomeDemo2FeaturesS1,
    title: "Launchpad & Onboarding",
    text: "Roll out Launchpad, develop project tools, and host events.",
  },
  {
    img: HomeDemo2FeaturesS2,
    title: "ISO & Engagement",
    text: "Introduce ISO platform, develop review system, and organize events.",
  },
  {
    img: HomeDemo2FeaturesS3,
    title: "Security & Trust",
    text: "Implement protection mechanisms, publish reports, and host security events. ",
  },
  {
    img: HomeDemo2FeaturesS4,
    title: "Team & Skill Enhancement",
    text: "Recruit experts, train the team, and launch interaction platform.",
  },
  {
    img: HomeDemo2FeaturesS4,
    title: "Integration & Outreach",
    text: "Ensure seamless platform integration, explore global expansion, and set future vision.",
  },
];

export const SmartContractinfoLeft = [
  {
    img: HomeDemo2FeaturesS1,
    title: "Foundation & Vision",
    text: "Establish core infrastructure and initiate key collaborations.",
  },
  {
    img: HomeDemo2FeaturesS2,
    title: "Social Mining",
    text: "Launch platform, reward user engagement, and host community events.",
  },
  {
    img: HomeDemo2FeaturesS3,
    title: "Trading Ecosystem",
    text: "Introduce AI-driven trading tools and conduct training sessions.",
  },
  {
    img: HomeDemo2FeaturesS4,
    title: "AI & Data Integration",
    text: "Develop AI algorithms, integrate insights, and host AI events.",
  },
  {
    img: HomeDemo2FeaturesS4,
    title: "AI Plugin Play",
    text: "Launch platform, create AI plugin library, and conduct workshops.",
  },
];

export const Features2InfoTop = [
  {
    img: HomeDemo2FeaturesF1,
    title: "Engagement & Rewards",
    text: "Through social mining, users actively engage and contribute, earning tokens in the process",
  },
  {
    img: HomeDemo2FeaturesF2,
    title: "AI Integration",
    text: "A plug-and-play AI platform, delivering results at unprecedented speed.",
  },
  {
    img: HomeDemo2FeaturesF3,
    title: "AI-Powered Insight & Assets",
    text: "Our Generative AI crafts unique digital assets, while Predictive AI offers insights.",
  },
];

export const Features2InfoDown = [
  {
    img: HomeDemo2FeaturesF4,
    title: "Unyielding Security",
    text: " Investor funds are shielded with top-tier security mechanisms.",
  },
  {
    img: HomeDemo2FeaturesF5,
    title: "Holistic Solutions",
    text: "Integrating social mining with tools tailored for project management and productivity.",
  },
  {
    img: HomeDemo2FeaturesF6,
    title: "Strategic Collaborations",
    text: "Our partnerships fuel growth and innovation.",
  },
];

export const OurBlogInfo = [
  {
    img: HomeDemo2BlogImg1,
    title: "What is this Token for?.",
  },
  {
    img: HomeDemo2BlogImg2,
    title: "The most powerful Token",
  },
  {
    img: HomeDemo2BlogImg3,
    title: "How to get trial version",
  },
];
