import {
  HomeDemo6Platforms1,
  HomeDemo6Platforms2,
  HomeDemo6Platforms3,
  HomeDemo6Platforms4,
  HomeDemo6Platforms5,
  HomeDemo6Platforms6,
  HomeDemo6IconsH1,
  HomeDemo6IconsH11,
  HomeDemo6IconsH2,
  HomeDemo6IconsH22,
  HomeDemo6IconsH3,
  HomeDemo6IconsH33,
  HomeDemo6FeaturesS1,
  HomeDemo6FeaturesS2,
  HomeDemo6FeaturesS3,
  HomeDemo6FeaturesF1,
  HomeDemo6FeaturesF3,
  HomeDemo6FeaturesF5,
  HomeDemo6TeamImg1,
  HomeDemo6TeamImg2,
  HomeDemo6TeamImg3,
  HomeDemo6TeamImg4,
  HomeDemo6BlogImg1,
  HomeDemo6BlogImg2,
  HomeDemo6BlogImg3,
} from "../../../utils/allImgs";

export const HowItWorksInfo = [
  {
    img1: HomeDemo6IconsH1,
    img2: HomeDemo6IconsH11,
    title: "24/7 Trading",
    text: "Our bots never sleep; they operate around the clock to ensure you never miss a trading opportunity.",
  },
  {
    img1: HomeDemo6IconsH2,
    img2: HomeDemo6IconsH22,
    title: "Diverse Strategies",
    text: "From high-frequency trading to long-term strategies, we offer bots designed to cater to your every trading need.",
  },
  {
    img1: HomeDemo6IconsH3,
    img2: HomeDemo6IconsH33,
    title: "Performance Enhancement",
    text: "Experience tangible improvements in your trading outcomes with our advanced, strategy-enhriching bots.",
  },
];

export const SmartContractinfo = [
  {
    img: HomeDemo6FeaturesS1,
    title: "Seamless Integration",
    text: "Once you choose an expert, their trading strategies are automatically mirrored in your portfolio. ",
  },
  {
    img: HomeDemo6FeaturesS2,
    title: "Diversification",
    text: "Spread your risk by following multiple traders, each with their own unique strategies.",
  },
  {
    img: HomeDemo6FeaturesS3,
    title: "Empowerment & Learning ",
    text: "Learn by doing. Witnessing experts' strategies first-hand provides a valuable, real-time education.",
  },
  // {
  //     img:HomeDemo6FeaturesS4,
  //     title:"Java virtual machine (JVM)"

  // },
];

export const SecTrustContent = [
  {
    img: HomeDemo6Platforms1,
    CheckIcon: true,
  },
  {
    img: HomeDemo6Platforms2,
    value: 8.9,
    CheckIcon: false,
  },
  {
    img: HomeDemo6Platforms3,
    CheckIcon: true,
  },
  {
    img: HomeDemo6Platforms4,
    CheckIcon: true,
  },
  {
    img: HomeDemo6Platforms5,
    value: 7.4,
    CheckIcon: false,
  },
  {
    img: HomeDemo6Platforms6,
    CheckIcon: true,
  },
];

export const TokenFeaturesTop = [
  {
    img: HomeDemo6FeaturesF3,
    title: "Personalized Trading Experience",
    text: "Your trading style is unique; our AI and bots adapt to your preferences for a tailored trading experience.",
  },
  // {
  //     img:HomeDemo6FeaturesF2,
  //     title:"Branded Sender ID"
  // }
];

export const TokenFeaturesMed = [
  {
    img: HomeDemo6FeaturesF1,
    title: "Predictive AI & Algorithmic Brilliance",
    text: "Our platform employs sophisticated AI and algorithms, turning crypto trading into a data-backed science.",
  },
  // {
  //     img:HomeDemo6FeaturesF4,
  //     title:"professional Routing"
  // },
];
export const TokenFeaturesDown = [
  {
    img: HomeDemo6FeaturesF5,
    title: "Real-Time Market Insights",
    text: "Get a live stream of market analytics and capitalize on every opportunity, big or small for your crypto assets.",
  },
  // {
  //     img:HomeDemo6FeaturesF6,
  //     title:"Two-Way Messaging"
  // }
];

export const OurTeamInfo = [
  {
    img: HomeDemo6TeamImg1,
    title: "Joman Helal",
    text: "Executive Officer",
  },
  {
    img: HomeDemo6TeamImg2,
    title: "Slans Alons",
    text: "Business Development",
  },
  {
    img: HomeDemo6TeamImg3,
    title: "Josha Michal",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo6TeamImg4,
    title: "Johan Wright",
    text: "Head of Marketing",
  },
];

export const OurBlogInfo = [
  {
    img: HomeDemo6BlogImg1,
    title: "What is this Token for?.",
  },
  {
    img: HomeDemo6BlogImg2,
    title: "The most powerful Token",
  },
  {
    img: HomeDemo6BlogImg3,
    title: "How to get trial version",
  },
];
