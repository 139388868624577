import {
  HomeDemo2TeamImg1,
  HomeDemo2TeamImg2,
  HomeDemo2TeamImg3,
  HomeDemo2TeamImg4,
} from "../../../utils/allImgs";

import socialMedium from "../../../assets/img/icons/icon-medium.png";
export const OurTeamInfo = [
  {
    img: HomeDemo2TeamImg1,
    link: "https://twitter.com/AltCoinsMoney_",
  },
  {
    img: HomeDemo2TeamImg2,
    link: "https://www.instagram.com/altcoinsmoney?igsh=MWR0cWttNjQ4NTVjNw==",
  },
  {
    img: socialMedium,
    link: "https://medium.com/@altcoinsmoneyofficial",
  },
  {
    img: HomeDemo2TeamImg3,
    link: "https://t.me/+NHQc7mdmcKNjY2E9",
  },
];
