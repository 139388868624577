import AboutContainer from "../containers/AboutContainer";

const AboutPage = () => {
  return (
    <>
      <AboutContainer />
    </>
  );
};

export default AboutPage;
