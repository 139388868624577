import BuyContainer from "../containers/BuyContainer";

const BuyPage = () => {
  return (
    <>
      <BuyContainer />
    </>
  );
};

export default BuyPage;
