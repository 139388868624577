export const tokenAdd = "0xd8cc37e68bdE249f5D37b2017341D6656b10103c"; // token address
export const tokenUsdtAdd = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"; // usdt address
export const contractAddr = "0x8E8dE6045891E729aDAd6f8fFEA1270734943bCf"; // presale address
export const chainId = 137; // chain id pls
export const tokenPrice = 0.000117; // token price in usd
export const targetUsdAmount = 517660; // target usd
export const endTime = "2024-08-30 00:00:01" // End time in date format

/*
At the initial token price of $0.00011765, for $1, you would get approximately 8,499.79 tokens
1USD = 8499.79
For $100, at the initial token price of $0.00011765, you would get approximately 849,978.75 tokens. ​
100USD = 849,978.75

1 USD = 1 X 8499.79 X 0.00011765 


*/
