import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import ConnectWalletButton from "../ConnectWalletButton";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { parseUnits } from "viem";

import presaleAbi from "../../abi/presale.json";
import erc20Abi from "../../abi/erc20.json";
import {
  tokenAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  tokenPrice,
} from "../../config";

import AIMLReceivedImg from "../../assets/img/icons/header-logo.png";

export default function UsdtCurrency() {
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtErrorMessage, setUsdtErrorMessage] = useState("");
  const [allowance, setAllowance] = useState(new BigNumber(0));

  const { address } = useAccount();

  const balanceUsdt = useBalance({
    address: address,
    token: tokenUsdtAdd,
    chainId: chainId,
    enabled: !!address,
    watch: true,
  });

  const balanceToken = useBalance({
    address: address,
    token: tokenAdd,
    chainId: chainId,
    enabled: !!address,
    watch: true,
  });

  const usdtBalanceBig = new BigNumber(balanceUsdt.data?.formatted);
  const isValidUsdt = usdtBalanceBig.gte(usdtAmount);

  const { config: approveConfig } = usePrepareContractWrite({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "approve",
    args: [contractAddr, parseUnits(usdtAmount, 6)],
    enabled: !!address && !!usdtAmount && !!isValidUsdt,
    chainId: chainId,
  });

  const { data: approveData, write: approveWrite } =
    useContractWrite(approveConfig);

  const {
    isLoading: approveIsLoading,
    isSuccess: approveIsSuccess,
    isError: approveIsError,
  } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const allowanceUsdtGet = useContractRead({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "allowance",
    enabled: !!address,
    args: [address, contractAddr],
    watch: true,
    chainId: chainId,
  });

  useEffect(() => {
    if (allowanceUsdtGet.data !== undefined) {
      const allowanceValue = new BigNumber(allowanceUsdtGet.data);
      setAllowance(allowanceValue);
    }
  }, [address, allowanceUsdtGet.data]);

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "buyTokensWithUSDT",
    args: [parseUnits(usdtAmount, 6)],
    enabled: !!address && !!allowance.gt(0) && !!usdtAmount,
    chainId: chainId,
  });

  const { data: purchaseData, write: purchaseWrite } = useContractWrite(config);

  const {
    isLoading: purchaseIsLoading,
    isSuccess: purchaseIsSuccess,
    isError: purchaseIsError,
  } = useWaitForTransaction({
    hash: purchaseData?.hash,
  });

  useEffect(() => {
    if (purchaseIsSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! Purchase Complete
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://polygonscan.com/tx/${purchaseData?.hash}`}
              target="blank"
            >
              View On Polygon
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [purchaseIsSuccess, purchaseData?.hash]);

  useEffect(() => {
    if (purchaseIsError) {
      toast.error(
        <div className="text-center py-2">Error! Something Went Wrong</div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [purchaseIsError]);

  useEffect(() => {
    if (purchaseData?.hash) {
      setAllowance(new BigNumber(0));
    }
  }, [purchaseData?.hash]);

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;
    const parsedAmount = parseFloat(inputValue);

    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setUsdtErrorMessage("Amount must be greater than zero");
    } else if (balanceUsdt.data?.formatted < parsedAmount) {
      setUsdtErrorMessage("Insufficient balance.");
    } else {
      setUsdtErrorMessage("");
    }
    setUsdtAmount(inputValue);
  };

  const getAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "getTokenAmountUSDT",
    args: [parseUnits(usdtAmount, 6)],
    enabled: !!usdtAmount,
    watch: true,
    chainId: chainId,
  });

  let getResult = new BigNumber(getAmount.data);
  const result = isNaN(getResult) ? 0 : getResult.toNumber();

  return (
    <div className="home-form">
      <form>
        <div className="home-form-input-container">
          <div>
            <div className="flex content-space-between">
              <div>Enter USDT Amount</div>
              <div>Max</div>
            </div>
            <div style={{ position: "relative" }}>
              <input
                className="inputbox-home-hero"
                placeholder={0}
                value={usdtAmount}
                onChange={handleAmountChange}
                step="any"
                style={{ borderRadius: "5px" }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "30px",
                  right: "0px",
                  bottom: "3px",
                }}
              >
                <img
                  src="https://static.vecteezy.com/system/resources/previews/013/373/699/original/tether-usdt-3d-rendering-isometric-icon-free-png.png"
                  alt=""
                  style={{ marginLeft: "-5px" }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex content-space-between">
              <div>$AIML You Receive</div>
            </div>
            <div style={{ position: "relative" }}>
              <input
                className="inputbox-home-hero"
                placeholder={result}
                style={{ borderRadius: "5px" }}
                readOnly
              />
              <div
                style={{
                  position: "absolute",
                  width: "30px",
                  right: "0px",
                  bottom: "3px",
                }}
              >
                <img
                  src={AIMLReceivedImg}
                  alt=""
                  style={{ marginLeft: "-5px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {usdtErrorMessage && <p style={{ color: "red" }}>{usdtErrorMessage}</p>}
        <div className="text-center">
          {address ? (
            <>
              <br />
              <div
                className="text-center"
                style={{ fontSize: "16px", color: "#fff" }}
              >
                YOUR AVAILABLE $AIML = {balanceToken?.data?.formatted}
              </div>
              <br />
              {allowance.toNumber() < usdtAmount ? (
                <button
                  type="button"
                  className="btn dream-btn mt-30 fadeInUp"
                  data-wow-delay="0.6s"
                  disabled={!approveWrite || approveIsLoading}
                  onClick={() => approveWrite()}
                >
                  {approveIsLoading ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn dream-btn mt-30 fadeInUp"
                  data-wow-delay="0.6s"
                  disabled={!purchaseWrite || purchaseIsLoading}
                  onClick={() => purchaseWrite()}
                >
                  {purchaseIsLoading ? "Buying..." : "Buy Now"}
                </button>
              )}
            </>
          ) : (
            <div>
              <ConnectWalletButton />
            </div>
          )}
          {address && (
            <div>
              <ConnectWalletButton />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
