import ContactContainer from "../containers/ContactContainer";

const ContactPage = () => {
  return (
    <>
      <ContactContainer />
    </>
  );
};

export default ContactPage;
