import React from "react";
import { toast } from "react-hot-toast";

export default function ContactForm() {
  async function onSubmitContact(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    formData.append("access_key", "56b84ec3-32d0-4817-8cc5-154645cd6139");
    formData.append("from_name", "AltCoins Money");
    formData.append("replyto", "support@altcoinsmoney.com");

    const object = Object.fromEntries(formData);
    console.log(object);
    const json = JSON.stringify(object);
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    console.log(res);

    if (res.success) {
      toast.success("Contact Form Submitted Successfully");
    }
  }
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          <div className="contact_form">
            <form onSubmit={onSubmitContact} id="main_contact_form">
              <div className="row">
                <div className="col-12">
                  <div id="success_fail_info" />
                </div>
                <div className="col-12 col-md-6">
                  <div className="group fadeInUp" data-wow-delay="0.2s">
                    <input type="text" name="name" id="name" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>Name</label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="group fadeInUp" data-wow-delay="0.3s">
                    <input type="email" name="email" id="email" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="group fadeInUp" data-wow-delay="0.4s">
                    <input type="text" name="subject" id="subject" required />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="group fadeInUp" data-wow-delay="0.5s">
                    <textarea
                      name="message"
                      id="message"
                      required
                      defaultValue={""}
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>Message</label>
                  </div>
                </div>
                <div
                  className="col-12 text-center fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <button type="submit" className="btn more-btn">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
