import React from 'react'
import { SlideCountdown } from 'react-fancy-countdown'
import { Line } from 'rc-progress'
import BigNumber from 'bignumber.js'
import { useContractRead } from 'wagmi'
import presaleAbi from '../../../abi/presale.json'
import UsdtCurrency from '../../Currency/UsdtCurrency'
import { contractAddr, chainId, tokenPrice, targetUsdAmount, endTime } from '../../../config'

function HeroHomeForm({ icoCounterClass = 'ico-counter dotted-bg' }) {
  const getTotalSold = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: 'usdRaised',
    watch: true,
    chainId: chainId,
  })

  const totalUsd = new BigNumber(getTotalSold.data).dividedBy(new BigNumber(10).pow(6)).toFixed(5) // prev 3
  const targetUsd = new BigNumber(targetUsdAmount) // target usd amount
  const progressPercentage = new BigNumber(totalUsd).dividedBy(targetUsd).multipliedBy(100)

  return (
    <div className={`${icoCounterClass} hero-home-form`}>
      <div className="counter-down">
        <div className="content">
          <div className="conuter-header">
            <h3 className="w-text text-center">TOKEN SALE ENDS IN</h3>
          </div>
          <div className="counterdown-content">
            <div className="count-down titled circled text-center">
              <SlideCountdown weeks={false} deadline={endTime} />
            </div>
            <h5 className="heading-home-form">BUY NOW BEFORE PRICE INCREASES!</h5>
            <div className="ico-progress">
              <ul className="list-unstyled list-inline clearfix mb-10">
                <li className="title"></li>
                <li className="strength"></li>
              </ul>
              <div className="current-progress">
                <Line
                  percent={progressPercentage.toNumber()}
                  trailWidth="3"
                  strokeWidth="4"
                  strokeColor="#fb881d"
                />
              </div>
              <span className="pull-left"></span>
              <span className="pull-right"></span>
            </div>
            <p className="white text-center text-16">
              USDT Raised: ${totalUsd} / ${targetUsdAmount}
            </p>
            <p className="white text-center">1 $AIML = ${tokenPrice} USDT</p>
            <UsdtCurrency />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroHomeForm
