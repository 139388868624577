import LaunchpadContainer from "../containers/LaunchpadContainer";

const LaunchpadPage = () => {
  return (
    <>
      <LaunchpadContainer />
    </>
  );
};

export default LaunchpadPage;
