const SecAbout = ({
  imgDwon = false,
  title,
  text,
  img,
  isHome = true,
  isLaunchpad = false,
}) => {
  return (
    <>
      {imgDwon ? (
        <section className="about-us-area section-padding-100 clearfix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 offset-lg-0">
                <div className="who-we-contant mt-s">
                  <div
                    className="dream-dots text-left"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <span className="gradient-text blue">{title}</span>
                  </div>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    {text}
                  </h4>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Our mission is to create an AI-enhanced decentralized
                    ecosystem that acts as a catalyst for individuals, startups,
                    and seasoned developers. Here, dreams meet reality.
                  </p>
                  {/* <p data-aos="fade-up" data-aos-delay="400">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit ipsa ut quasi adipisci voluptates, voluptatibus aliquid alias beatae reprehenderit incidunt iusto laboriosam.</p>
                    <a className="btn more-btn mt-30" href="#">Read More</a> */}
                </div>
              </div>
              <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                <div
                  className="welcome-meter"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img draggable="false" src={img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="about-us-area section-padding-100 clearfix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                <div
                  className="welcome-meter"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img draggable="false" src={img} alt="" />
                </div>
              </div>
              <div className="col-12 col-lg-6 offset-lg-0">
                <div className="who-we-contant mt-s">
                  <div
                    className="dream-dots text-left"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <span className="gradient-text ">
                      {isHome && "Decentralized Trading Platform"}
                      {isLaunchpad && "Decentralized launchpad"}
                    </span>
                  </div>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    {isHome &&
                      "Connect blockchain to the real world and start crypto trading"}
                    {isLaunchpad &&
                      "Connect blockchain to the real world and start fundraising"}
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    {isHome &&
                      "A decentralized trading platform bridges the gap between blockchain technology and the real world, enabling secure and transparent crypto trading."}
                    {isLaunchpad &&
                      "A decentralized launchpad connects innovative real-world ideas with blockchain technology, facilitating secure and transparent fundraising."}
                  </p>
                  <p data-aos="fade-up" data-aos-delay="400">
                    {isHome &&
                      "It offers autonomy, reduced fees, and direct peer-to-peer transactions without intermediaries, revolutionizing digital asset exchange."}
                    {isLaunchpad &&
                      "It empowers entrepreneurs by offering a platform for tokenizing projects, enabling community-driven support, an reducing traditional funding barriers, revolutionizing how ventures are kickstarted."}
                  </p>
                  <a className="btn more-btn mt-30" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SecAbout;
