import { Link } from "react-router-dom";
import SectionHeading from "../OurTeam/SectionHeading";

const OurTeam = ({ data, ClassSpanTitle }) => {
  return (
    <section className="our_team_area section-padding-100 clearfix" id="team">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading
              title="Connect & Join Us"
              text="Join Our Social Media Channel For More Opportunities "
              ClassSpanTitle={ClassSpanTitle}
            />
          </div>
        </div>
        <div className="social-media-icons-container">
          {data &&
            data.map((item, key) => (
              <div className="" key={key}>
                <div className="single-team-member" data-aos="fade-up">
                  <div className="team-member-thumb">
                    <Link to={item.link} target="_blank">
                      <img
                        draggable="false"
                        src={item.img}
                        className="center-block"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="team-info">
                    <h5 className="w-text">{item.title}</h5>
                    <p className="g-text">{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
