import React from "react";
import "./TokenStages.css";
import { stagesData } from "./stagesData";

function TokenStages() {
  return (
    <>
      <section class="features section-padding-0-100">
        <div class="container">
          <div class="section-heading text-center">
            <div
              class="dream-dots justify-content-center aos-init aos-animate"
              data-aos="fade-up"
            >
              <span class="">Token Stages</span>
            </div>
            <h2 data-aos="fade-up" class="aos-init aos-animate">
              AltCoinsMoney Presale Stages
            </h2>
            <p data-aos="fade-up" class="aos-init aos-animate">
              Experience an exhilarating financial journey with an investment
              growth of 458% by entering at Stage 1 and exiting at Stage 9.
            </p>
          </div>
          <div className="stage-container">
            {stagesData.map((item) => (
              <div class="pricing-item stage">
                <label className="heading">
                  <strong>Stage {item.stage}</strong>
                </label>
                <h4 className="sub-heading">Total Tokens {item.totalTokens}</h4>
                <div class="stage-price">
                  Per Token Price = {item.perTokenPrice}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default TokenStages;
