import React from "react";
import FaqContainer from "../containers/FaqContainer";

function FaqPage() {
  return (
    <>
      <FaqContainer />
    </>
  );
}

export default FaqPage;
