const TermsService = ({ title }) => {
  return (
    <>
      {
        <section className="about-us-area section-padding-100 clearfix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 ">
                <div className="who-we-contant mt-5">
                  <div
                    className="dream-dots"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <h2 className="gradient-text">{title}</h2>
                  </div>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    1. Terms
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    By accessing this Website, accessible from
                    https://altcoinsmoney.com/, you are agreeing to be bound by
                    these Website Terms and Conditions of Use and agree that you
                    are responsible for the agreement with any applicable local
                    laws. If you disagree with any of these terms, you are
                    prohibited from accessing this site. The materials contained
                    in this Website are protected by copyright and trade mark
                    law.
                  </p>

                  <h4 data-aos="fade-up" data-aos-delay="300">
                    2. Use License
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    Permission is granted to temporarily download one copy of
                    the materials on AltCoins Money's Website for personal,
                    non-commercial transitory viewing only. This is the grant of
                    a license, not a transfer of title, and under this license
                    you may not:
                  </p>
                  <p data-aos="fade-up" data-aos-delay="300">
                    <ul>
                      <li>- modify or copy the materials.</li>
                      <li>
                        - use the materials for any commercial purpose or for
                        any public display.
                      </li>
                      <li>
                        - attempt to reverse engineer any software contained on
                        AltCoins Money's Website.
                      </li>
                      <li>
                        - remove any copyright or other proprietary notations
                        from the materials or transferring the materials to
                        another person or "mirror" the materials on any other
                        server.
                      </li>
                    </ul>
                  </p>
                  <p data-aos="fade-up" data-aos-delay="300">
                    This will let AltCoins Money to terminate upon violations of
                    any of these restrictions. Upon termination, your viewing
                    right will also be terminated and you should destroy any
                    downloaded materials in your possession whether it is
                    printed or electronic format.
                  </p>

                  <h4 data-aos="fade-up" data-aos-delay="300">
                    3. Disclaimer
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    All the materials on AltCoins Money's Website are provided
                    'as is'. AltCoins Money makes no warranties, may it be
                    expressed or implied. therefore negates all other
                    warranties. Furthermore, AltCoins Money does not make any
                    representations concerning the accuracy or reliability of
                    the use of the materials on its Website or otherwise
                    relating to such materials or any sites linked to this
                    Website.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    4. Limitations
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoins Money or its suppliers will not be hold accountable
                    for any damages that will arise with the use or inability to
                    use the materials on AltCoins Money's Website, even if
                    AltCoins Money or an authorize representative of this
                    Website has been notified, orally or written of the
                    possibility of such damage. Some jurisdiction does not allow
                    limitations on implied warranties or limitations of
                    liability for incidental damages, these limitations may not
                    apply to you.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    5. Revisions and Errata
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    The materials appearing on AltCoins Money's Website may
                    include technical, typographical, or photographic errors.
                    AltCoins Money will not promise that any of the materials in
                    this Website are accurate, complete, or current. AltCoins
                    Money may change the materials contained on its Website at
                    any time without notice. AltCoins Money does not make any
                    commitment to update the materials.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    6. Links
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoins Money has not reviewed all of the sites linked to
                    its Website and is not responsible for the contents of any
                    such linked site. The presence of any link does not imply
                    endorsement by AltCoins Money of the site. The use of any
                    linked website is at the user's own risk.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    7. Site Terms of Use Modifications
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoins Money may revise these Terms of Use for its Website
                    at any time without prior notice. By using this Website, you
                    are agreeing to be bound by the current version of these
                    Terms and Conditions of Use.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    8. Your Privacy
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    Please read our Privacy Policy.
                  </p>
                  <h4 data-aos="fade-up" data-aos-delay="300">
                    9. Governing Law
                  </h4>

                  <p data-aos="fade-up" data-aos-delay="300">
                    Any claim related to AltCoins Money's Website shall be
                    governed by the laws of in without regards to its conflict
                    of law provisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default TermsService;
