export const stagesData = [
  {
    stage: 1,
    totalTokens: "4.44 billion",
    perTokenPrice: "$0.00011765",
  },
  {
    stage: 2,
    totalTokens: "3.58 billion",
    perTokenPrice: "$0.00014588",
  },
  {
    stage: 3,
    totalTokens: "2.89 billion",
    perTokenPrice: "$0.00018089",
  },
  {
    stage: 4,
    totalTokens: "2.33 billion",
    perTokenPrice: "$0.00022431",
  },
  {
    stage: 5,
    totalTokens: "1.88 billion",
    perTokenPrice: "$0.00027814",
  },
  {
    stage: 6,
    totalTokens: "1.52 billion",
    perTokenPrice: "$0.00034490",
  },
  {
    stage: 7,
    totalTokens: "1.22 billion",
    perTokenPrice: "$0.00042767",
  },
  {
    stage: 8,
    totalTokens: "985 million",
    perTokenPrice: "$0.00053031",
  },
  {
    stage: 9,
    totalTokens: "795 million",
    perTokenPrice: "$0.00065759",
  },
];
