import HomeContainer from "../containers/HomeContainer";

const HomePage = () => {
  return (
    <>
      <HomeContainer />
    </>
  );
};

export default HomePage;
