import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import "./style/HomeDemo2.scss";

import Header from "../../layouts/Header";
import FooterPages from "../../layouts/Footer/FooterPages";

import OurTeam from "../../components/Common/OurTeam";
import PrivacyPolicy from "../../components/Common/PrivacyPolicy/index.jsx";
import { OurTeamInfo } from "../../components/Common/OurTeam/OurTeamInfo.js";

const PrivacyPolicyContainer = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <div>
      <Header Title="Altcoins Money - Privacy Policy" />
      <PrivacyPolicy title="Privacy Policy" />
      <OurTeam data={OurTeamInfo} ClassSpanTitle="gradient-text" />
      <FooterPages ClassSpanTitle="gradient-text" />
    </div>
  );
};

export default PrivacyPolicyContainer;
