import TradingContainer from "../containers/TradingContainer";

const TradingPage = () => {
  return (
    <>
      <TradingContainer />
    </>
  );
};

export default TradingPage;
