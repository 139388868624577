const FrequentlyAskQuestion = ({ title }) => {
  return (
    <>
      {
        <section className="about-us-area section-padding-100 clearfix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 ">
                <div className="who-we-contant mt-5">
                  <div
                    className="dream-dots"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <h2 className="gradient-text">{title}</h2>
                  </div>
                  <br />
                  <br />
                  <h5 data-aos="fade-up" data-aos-delay="300">
                    How can I purchase AltCoinsMoney Token?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    You can buy AltCoinsMoney Token by connecting your metamask
                    or trust wallet using USDT( theater).
                  </p>
                  <br />
                  <h5 data-aos="fade-up" data-aos-delay="300">
                    On which blockchain is AltCoinsMoney Token based?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoinsMoney Token operates on the [Polygon] blockchain,
                    known for its security and efficiency.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Could you explain the AltCoinsMoney pre-sale process?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    The presale involves early purchase of tokens at a set price
                    before public listing, It has six stages and price will be
                    increased in each stage please check on the AltCoinsMoney
                    platform.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    When will I get the tokens post-purchase?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Tokens are typically credited to your wallet instantly after
                    the purchase transaction is confirmed.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Post-purchase, why aren't my tokens visible in my wallet?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    This could be due to a delay in transaction processing or
                    the need to add AltCoinsMoney Token as a custom token in
                    your wallet.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Is an independent wallet mandatory for token purchase?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Yes, owning an independent, compatible wallet is necessary
                    for purchasing and holding AltCoinsMoney Tokens.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    I'm facing transaction issues. What should I do?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Contact customer support or check the FAQ/help section on
                    the platform where you're conducting the transaction.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Where will AltCoinsMoney be available for trading?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoinsMoney will be available on various cryptocurrency
                    exchanges, which will be announced on our official channels.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Can you provide the AltCoinsMoney contract address?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    The contract address for AltCoinsMoney Token is
                    [0xd8cc37e68bdE249f5D37b2017341D6656b10103c], always
                    verified before transactions.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    Has AltCoinsMoney undergone any audits?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    AltCoinsMoney has undergone audits by Coinsult, ensuring
                    security and compliance.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    How will the capital from the AltCoinsMoney presale be
                    utilized?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Funds from the presale will be used for project development,
                    marketing, partnerships, and other operational needs.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    What advantages does the AltCoinsMoney presale offer?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    The presale offers benefits like discounted prices, early
                    access, and potential bonuses for early adopters.
                  </p>
                  <br />

                  <h5 data-aos="fade-up" data-aos-delay="300">
                    How can I remain informed about AltCoinsMoney's progress?
                  </h5>
                  <p data-aos="fade-up" data-aos-delay="300">
                    Stay updated by following our official social media
                    channels, subscribing to our newsletter, and regularly
                    checking our website.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default FrequentlyAskQuestion;
