import React from "react";
import PrivacyPolicyContainer from "../containers/PrivacyPolicyContainer";

function PrivacyPolicyPage() {
  return (
    <>
      <PrivacyPolicyContainer />
    </>
  );
}

export default PrivacyPolicyPage;
