import React from "react";
import TermsServiceContainer from "../containers/TermsServiceContainer";

function TermsServicePage() {
  return (
    <>
      <TermsServiceContainer />
    </>
  );
}

export default TermsServicePage;
