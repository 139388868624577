import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils/index.jsx";

import "./style/HomeDemo2.scss";

import Header from "../../layouts/Header/index.jsx";
import FooterPages from "../../layouts/Footer/FooterPages/index.jsx";

import OurTeam from "../../components/Common/OurTeam/index.jsx";
import FrequentlyAskQuestion from "../../components/Common/FrequentlyAskQuestion/index.jsx";
import { OurTeamInfo } from "../../components/Common/OurTeam/OurTeamInfo.js";

const FaqContainer = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <div>
      <Header Title="Altcoins Money - FAQ" />
      <FrequentlyAskQuestion title="Frequently Asked Questions" />
      <OurTeam data={OurTeamInfo} ClassSpanTitle="gradient-text" />
      <FooterPages ClassSpanTitle="gradient-text" />
    </div>
  );
};

export default FaqContainer;
